@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Made';
    font-weight: 500;
    src: url('./fonts/made-regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Made';
    font-weight: 700;
    src: url('./fonts/made-bold.woff') format('woff');
  }
  @font-face {
    font-family: 'Made';
    font-weight: 600;
    src: url('./fonts/made-medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Made';
    font-weight: 400;
    src: url('./fonts/made-light.woff') format('woff');
  }
  @font-face {
    font-family: 'Made';
    font-weight: 300;
    src: url('./fonts/made-thin.woff') format('woff');
  }
}

.form-label {
  @apply text-base !important;
}

strong {
  @apply font-medium;
}

.app-container {
  @apply mt-16 md:mt-36;
}

.page-container-sm {
  @apply max-w-lg mx-auto px-4 sm:px-6 lg:px-8 py-10 md:py-20;
}

.section-container-sm {
  @apply max-w-lg mx-auto;
}

.page-container-md {
  @apply mx-auto max-w-md px-4 sm:max-w-3xl px-4 sm:px-6 lg:px-8 lg:max-w-2xl py-10 md:py-20;
}

.page-container-lg {
  @apply mx-auto max-w-md px-4 sm:max-w-3xl px-4 sm:px-6 lg:px-8 lg:max-w-7xl py-10 md:py-20;
}

/* Report Table Styles */
.text-vertical-td {
  vertical-align: middle !important;
}
.text-vertical-table {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  @apply text-lg font-medium m-1 text-center;
}
.max-col-width {
  word-wrap: break-word;
  @apply w-20 text-center;
}

/* React Dates Styles */
#mci-single-date-picker {
}

.DateInput_input {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 6px 8px !important;
  color: #000 !important;
  border-bottom: 0px !important;
}

.SingleDatePickerInput {
  border-width: 2px !important;
}

.SingleDatePickerInput__withBorder {
  border-width: 2px !important;
  border-radius: 2px !important;
  border-color: rgb(229, 231, 235) !important;
}

/* NOTE: the order of these styles DO matter */
/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid red;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #d8d5f1 !important;
  color: #0a043d !important;
  border-color: #0a043d !important;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #d8d5f1 !important;
  color: white !important;
}

.DateInput_input__focused {
  border-bottom: #e6eef1 !important;
}

.CalendarMonth_caption {
  font-size: 16px;
}
.CalendarMonth_caption strong {
  font-weight: 500;
}

/* Form Fileinput style */
input[type='file']::-webkit-file-upload-button,
input[type='file']::file-selector-button {
  @apply text-white bg-brand-blue hover:bg-brand-blue-light hover:text-brand-blue transition-colors font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
  margin-inline-start: -1rem;
  margin-inline-end: 1rem;
}

/* Footer Nav Items styling - remove first item border */
.nav-item-0 > a > div {
  padding-left: 0px !important;
}

.nav-item-0 > a > div:before {
  width: 0px !important;
}
